import axios from 'axios';

import UserLocalStorage from 'utils/userLocalStorage';

import instance from '../axios';
import * as endpoints from './endpoints';

const cancelInFlightGetRequest = (path) => {
  const cancelTokenSource = axios.CancelToken.source();
  return {
    request: instance.get(path, {
      cancelToken: cancelTokenSource.token,
    }),
    cancelTokenSource,
  };
};

export const getGooglePlaceDetail = (placeId) => {
  return instance.get(endpoints.GOOGLE_PLACE_DETAIL_API_PATH.replace('{}', placeId));
};

export const loginUser = (payload) => {
  delete instance.defaults.headers.common['Authorization'];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const registerUser = (payload) => {
  delete instance.defaults.headers.common['Authorization'];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.REGISTER_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserDetail = () => {
  return new Promise((resolve, reject) => {
    const lsValue = new UserLocalStorage().value;
    const { token } = lsValue;
    const userId = lsValue.user_id;

    if (token && userId) {
      instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      instance
        .get(endpoints.USER_DETAIL_API_PATH.replace('{}', userId))
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const getUserNoOfBookingsWithBarber = (barber_id) => {
  return new Promise((resolve, reject) => {
    const lsValue = new UserLocalStorage().value;
    const { token } = lsValue;
    const userId = lsValue.user_id;

    if (token && userId) {
      instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      instance
        .post(endpoints.USER_BOOKING_WITH_BARBR.replace('{}', userId), {
          barber_id,
        })
        .then((response) => {
          resolve(response?.data?.no_of_bookings);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject(null);
    }
  });
};

export const updateUser = (userId, payload) => {
  return instance.patch(endpoints.USER_DETAIL_API_PATH.replace('{}', userId), payload);
};

export const getUserWallet = (userId) => {
  return instance.get(endpoints.USER_WALLET_API_PATH.replace('{}', userId));
};

export const sendPasswordResetVerificationOtp = (payload) => {
  return instance.post(endpoints.PASSWORD_RESET_OTP_API_PATH, payload);
};

export const updateUserPassword = (payload) => {
  return instance.post(endpoints.PASSWORD_UPDATE_API_PATH, payload);
};

export const sendVerificationOtp = (userId, payload) => {
  return instance.post(endpoints.SEND_VERIFICATION_OTP_API_PATH.replace('{}', userId), payload);
};

export const verifyVerificationOtp = (userId, payload) => {
  return instance.post(endpoints.VERIFY_VERIFICATION_OTP_API_PATH.replace('{}', userId), payload);
};

export const verificationPhoneLookup = (userId) => {
  return instance.get(endpoints.PHONE_VERIFICATION_LOOKUP_API_PATH.replace('{}', userId));
};

export const getBarberDetail = (username, querystring = '') => {
  let path = endpoints.BARBER_DETAIL_API_PATH.replace('{}', username);
  path = path.concat('?', querystring);
  return instance.get(path);
};

export const getBarberReviews = (userId, querystring = '') => {
  let path = endpoints.BARBER_REVIEWS_API_PATH.replace('{}', userId);
  path = path.concat('?', querystring);
  return cancelInFlightGetRequest(path);
};

export const getBarberPaymentMethods = (profileId) => {
  return instance.get(endpoints.BARBER_PAYMENT_METHODS_API_PATH.replace('{}', profileId));
};

export const createBarberPaymentIntent = (profileId, payload) => {
  return instance.post(endpoints.BARBER_PAYMENT_INTENT_API_PATH.replace('{}', profileId), payload);
};

export const getCountries = () => {
  return instance.get(endpoints.COUNTRY_LIST_API_PATH);
};

export const getBarberShopReviews = (userId, querystring = '') => {
  let path = endpoints.BARBER_SHOP_REVIEWS_API_PATH.replace('{}', userId);
  path = path.concat('?', querystring);
  return cancelInFlightGetRequest(path);
};

export const getBarberBookingSlots = (profileId, serviceType, payload, querystring = '') => {
  let path = endpoints.BARBER_BOOKING_SLOTS_API_PATH.replace('{}', profileId);
  path = path.replace('{type}', serviceType);
  path = path.concat('?', querystring);
  return instance.post(path, payload);
};

export const getUserBookingAddresses = (userId) => {
  return instance.get(endpoints.USER_BOOKING_ADDRESSES_API_PATH.replace('{}', userId));
};

export const createBarberBooking = (profileId, locationType, payload) => {
  let path = endpoints.BARBER_BOOKING_API_PATH;
  path = path.replace('{}', profileId);
  path = path.replace('{location_type}', locationType);

  return instance.post(path, payload);
};

export const deleteBarberBooking = (profileId, bookingId) => {
  let path = endpoints.BARBER_BOOKING_DELETE_API_PATH;
  path = path.replace('{}', profileId);
  path = path.replace('{booking_id}', bookingId);

  return instance.post(path);
};

export const getUserBookings = (userId, querystring = '') => {
  const path = endpoints.USER_BOOKING_API_PATH.replace('{}', userId);
  return instance.get(path.concat('?', querystring));
};

export const getUserBookingDetail = (userId, bookingId, querystring = '') => {
  let path = endpoints.USER_BOOKING_DETAIL_API_PATH;
  path = path.replace('{}', userId);
  path = path.concat('?', querystring);

  return instance.get(path.replace('{booking_id}', bookingId));
};

export const cancelUserBooking = (barberProfileId, bookingId) => {
  let path = endpoints.USER_BOOKING_CANCEL_API_PATH;
  path = path.replace('{}', barberProfileId);

  return instance.post(path.replace('{booking_id}', bookingId));
};
export const cancelRecurringBooking = (payload) => {
  let path = endpoints.CANCEL_RECURRING_BOOKING;

  return instance.post(path, payload);
};

export const deleteUserBooking = (barberProfileId, bookingId) => {
  let path = endpoints.USER_BOOKING_DELETE_API_PATH;
  path = path.replace('{}', barberProfileId);

  return instance.post(path.replace('{booking_id}', bookingId));
};

export const createBookingReview = (bookingId, payload, querystring = '') => {
  let path = endpoints.BOOKING_REVIEW_API_PATH;
  path = path.replace('{}', bookingId);
  return instance.post(path.concat('?', querystring), payload);
};
export const createCustomerReview = (payload) => {
  return instance.post(endpoints.CUSTOMER_REVIEW_API_PATH, payload);
};

export const calculateBarberCalloutDistance = (profileId, payload) => {
  return instance.post(endpoints.BARBER_CALLOUT_DISTANCE_CALCULATE_API_PATH.replace('{}', profileId), payload);
};

export const calculateBarberCalloutDeliveryFee = (profileId, payload) => {
  return instance.post(endpoints.BARBER_CALLOUT_DELIVERY_FEE_CALCULATE_API_PATH.replace('{}', profileId), payload);
};

export const validateBarberDiscountCode = (profileId, payload) => {
  return instance.post(endpoints.BARBER_BOOKING_VALIDATE_DISCOUNT_CODE_API_PATH.replace('{}', profileId), payload);
};

export const getBarberClientDetail = (profileId) => {
  return instance.post(endpoints.BARBER_CLIENT_DETAIL_API_PATH.replace('{}', profileId));
};

export const getBarberBookingDetailByReviewKey = (querystring) => {
  return instance.get(endpoints.USER_BOOKING_REVIEW_DATA_API_PATH.concat('?', querystring));
};

export const barberProfileViewed = (username) => {
  return instance.post(endpoints.BARBER_VISITED_API_PATH.replace('{}', username));
};

export const barberShopViewed = (username) => {
  return instance.get(endpoints.SHOP_VISITED_API_PATH.replace('{}', username));
};

export const updateBookingTime = (bookingId, payload) => {
  return instance.patch(endpoints.BOOKING_UPDATE_TIME.replace('{booking_id}', bookingId), payload);
};

export const chargeCancellationFeeCustomer = (customerId, barberId, payload) => {
  let url = endpoints.CHARGE_FEE_CUSTOMER;
  url = url.replace('{customerId}', customerId);
  url = url.replace('{barberId}', barberId);
  return instance.post(url, payload);
};

export const saveCardOfCustomer = (customerId, payload) => {
  let url = endpoints.SAVE_CUSTOMER_CARD;
  url = url.replace('{customerId}', customerId);
  return instance.post(url, payload);
};

export const sendBookingBlockNotification = (payload) => {
  const url = endpoints.CUSTOM_NOTIFICATION;
  return instance.post(url, payload);
};
