export const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

export const isObject = (val) => {
  return val instanceof Object;
};

export const sumOff = (arr, key) => {
  return arr.reduce((total, obj) => parseFloat(obj[key]) + total, 0);
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getGooglePlaceValue = (place, types) => {
  let value = null;
  place?.address_components?.forEach((entry) => {
    if (types.includes(entry.types?.[0])) {
      value = entry.long_name;
    }
  });
  return value;
};

export const allowOnlyIntegers = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  }
};

export const toFixed = (value, pos = 1) => {
  return value && value.toFixed(pos);
};

export const fixedNumber = (num) => {
  return toFixed(Math.round(num * 100) / 100, 2);
};

export const loadScript = (scriptSrc) => {
  const script = document.createElement('script');
  script.src = scriptSrc;
  script.type = 'text/javascript';
  document.body.appendChild(script);
};

export const initials = (value, separator = '') => {
  return value
    .split(' ')
    .map((n) => n[0])
    .join(separator);
};
