import React, { useReducer, useContext, createContext } from 'react';

const SignUpReviewModalContext = createContext();

export const SignUpReviewModalProvider = ({ reducer, initialState, children }) => {
  const contextValue = useReducer(reducer, initialState);
  return <SignUpReviewModalContext.Provider value={contextValue}>{children}</SignUpReviewModalContext.Provider>;
};

export const SignUpReviewModalConsumer = () => {
  const contextValue = useContext(SignUpReviewModalContext);
  return contextValue;
};
