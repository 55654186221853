import dayjs from 'dayjs';
import React from 'react';
import ReactDOM from 'react-dom';
import timezone from 'dayjs/plugin/timezone';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { barberProfilesReducer, initialState as barberProfiles } from './reducers/barberProfilesReducer';
import { reviewSignUpBoolReducer, initialState as SigUpModalReviewState } from './reducers/reviewModalAuthReducer';
import { BarberProfilesProvider } from './context/barberProfiles';
import { accountDataReducer, initialState as accountData } from './reducers/accountDataReducer';
import { AccountDataProvider } from './context/accountData';
import { countriesReducer, initialState as countries } from './reducers/countriesReducer';
import { CountriesProvider } from './context/countries';
import LazyLoadSentry from 'lazyLoadSentry';

import { SignUpReviewModalProvider } from 'context/reviewModalAuth';

import './styles/style.scss';

dayjs.extend(timezone);
dayjs.tz.setDefault('Etc/UTC');

ReactDOM.render(
  <React.StrictMode>
    <LazyLoadSentry>
      <CountriesProvider initialState={countries} reducer={countriesReducer}>
        <AccountDataProvider initialState={accountData} reducer={accountDataReducer}>
          <BarberProfilesProvider initialState={barberProfiles} reducer={barberProfilesReducer}>
            <SignUpReviewModalProvider initialState={SigUpModalReviewState} reducer={reviewSignUpBoolReducer}>
              <App />
            </SignUpReviewModalProvider>
          </BarberProfilesProvider>
        </AccountDataProvider>
      </CountriesProvider>
    </LazyLoadSentry>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
