import { isEmpty } from '_dash';
import { AccountDataConsumer } from '../context/accountData';
import { setAccountData as accountSetter } from '../reducers/accountDataReducer';

const AccountHook = (BaseComponent) => (props) => {
  const [accountDataConsumer, dispatch] = AccountDataConsumer();
  const accountData = accountDataConsumer.detail || {};

  const setAccountData = (data) => {
    dispatch(accountSetter(data));
  };

  return <BaseComponent {...props} accountData={accountData} isLoggedIn={!isEmpty(accountData)} setAccountData={setAccountData} />;
};

export default AccountHook;
