import { Suspense } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from '../../pages/404Page';
import RouteWithSubRoutes from '../routeWithSubRoutes';
import './style.scss';

const RenderRoutes = ({ routes }) => (
  <Suspense
    fallback={
      <div className='route-loader'>
        <Spinner animation='border' />
      </div>
    }>
    <Switch>
      {routes.map((route) => (
        <RouteWithSubRoutes key={route.name} {...route} />
      ))}
      <Route component={NotFoundPage} />
    </Switch>
  </Suspense>
);

export default RenderRoutes;
