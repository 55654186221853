export const initialState = [];

const SET_COUNTRIES = 'SET_COUNTRIES';

export const setCountries = (data) => {
  return {
    payload: data,
    type: SET_COUNTRIES,
  };
};

export const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return {
        countries: action.payload,
      };
    default:
      return { countries: state };
  }
};

export default countriesReducer;
