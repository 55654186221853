import React from 'react';

import UserLocalStorage from 'utils/userLocalStorage';

const UserLocalStorageHook = (BaseComponent) => (props) => {
  const ls = new UserLocalStorage();

  const getAccessToken = () => {
    return ls.value.token;
  };

  const getUserId = () => {
    return ls.value.user_id;
  };

  return <BaseComponent {...props} userId={getUserId()} accessToken={getAccessToken()} setLocalStorage={(value) => ls.setValue(value)} />;
};

export default UserLocalStorageHook;
