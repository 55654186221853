import React, { createContext, useState, useEffect, useContext } from 'react';

const HelpCrunchContext = createContext();

export const HelpCrunchProvider = ({ children, accountData }) => {
  const [isHelpCrunchReady, setIsHelpCrunchReady] = useState(false);

  const removeHelpCrunch = () => {
    setTimeout(() => {
      const script = document.querySelector(`script[src^="https://${window.helpcrunchSettings.organization}.widget.helpcrunch.com/"]`);
      if (script) {
        script.remove();
      }
      const chatTags = document.getElementsByClassName('helpcrunch-iframe-wrapper');
      if (chatTags) {
        const chatTagsArray = Array.from(chatTags);
        chatTagsArray.forEach((chatTag) => {
          chatTag.parentNode.removeChild(chatTag);
        });
      }
    }, 0);
  };

  useEffect(() => {
    setTimeout(() => {
      let userData = undefined;
      if (accountData && accountData?.id) {
        userData = {
          user_id: accountData.id,
          name: accountData.full_name,
          email: accountData.email,
          phone: accountData.phone_number,
        };
      }

      // Define the HelpCrunch settings
      window.helpcrunchSettings = {
        organization: 'barbr',
        appId: '619a8f90-3723-441c-bd54-078fcf6d85bb',
        user: userData,
      };

      // Function to dynamically load the HelpCrunch widget
      (function (w, d) {
        var hS = w.helpcrunchSettings;
        if (!hS || !hS.organization) {
          return;
        }
        var widgetSrc = 'https://' + hS.organization + '.widget.helpcrunch.com/';
        w.HelpCrunch = function () {
          w.HelpCrunch.q.push(arguments);
        };
        w.HelpCrunch.q = [];
        w.HelpCrunch('onReady', () => {
          window?.HelpCrunch('closeChat');
          window?.HelpCrunch('hideChatWidget');
          setIsHelpCrunchReady(true);
        });
        function r() {
          if (d.querySelector('script[src="' + widgetSrc + '"]')) {
            return;
          }
          var s = d.createElement('script');
          s.async = 1;
          s.type = 'text/javascript';
          s.src = widgetSrc;
          (d.body || d.head).appendChild(s);
        }
        if (d.readyState === 'complete' || hS.loadImmediately) {
          r();
        } else if (w.attachEvent) {
          w.attachEvent('onload', r);
        } else {
          w.addEventListener('load', r, false);
        }
      })(window, document);
    }, 4500);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      setIsHelpCrunchReady(false);
      removeHelpCrunch();
    };
  }, [accountData]);

  return <HelpCrunchContext.Provider value={{ isHelpCrunchReady }}>{children}</HelpCrunchContext.Provider>;
};

export const useHelpCrunch = () => useContext(HelpCrunchContext);
