import { AUTHORIZATION_KEY } from 'global_constants';

class UserLocalStorage {
  getPayload(value) {
    return {
      user_id: value.id,
      token: value.access_token,
    };
  }

  get value() {
    try {
      return JSON.parse(localStorage.getItem(AUTHORIZATION_KEY)) || {};
    } catch {
      return {};
    }
  }

  setValue(value) {
    localStorage.setItem(AUTHORIZATION_KEY, JSON.stringify(this.getPayload(value)));
    return;
  }
}

export default UserLocalStorage;
