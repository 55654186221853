import React, { useReducer, useContext, createContext } from 'react';

const BarberProfilesContext = createContext();

export const BarberProfilesProvider = ({ reducer, initialState, children }) => {
  const contextValue = useReducer(reducer, initialState);
  return <BarberProfilesContext.Provider value={contextValue}>{children}</BarberProfilesContext.Provider>;
};

export const BarberProfilesConsumer = () => {
  const contextValue = useContext(BarberProfilesContext);
  return contextValue;
};
