import { Fragment, useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { BrowserRouter } from 'react-router-dom';

import { isEmpty } from '_dash';
import { getUserDetail } from 'actions';

import AccountHook from 'hooks/account';
import ScrollToTop from 'components/scrollToTop';
import UserLocalStorageHook from 'hooks/userLocalstorage';
import AppRoutes from 'appRoutes';
import { HelpCrunchProvider } from 'context/helpCrunchContext';

const App = ({ accountData, setAccountData }) => {
  const isLoggedIn = !isEmpty(accountData);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isEmpty(accountData)) {
      getUserDetail()
        .then((response) => {
          setAccountData(response);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }

    if (window.location) {
      const { origin, pathname, search } = window.location;
      if (origin && pathname) {
        if (pathname && pathname.length > 2) {
          const pathnameArray = pathname.split('/');
          let lowercaseUrl = pathnameArray
            .map((url, index) => {
              if (index < 2) {
                return url.trim().toLowerCase();
              }
              return url;
            })
            .join('/');
          lowercaseUrl = `${origin}${lowercaseUrl}${search ? search : ''}`;
          const currentUrl = window.location.href;
          if (currentUrl !== lowercaseUrl) {
            window.history.replaceState(null, null, lowercaseUrl);
          }
        }
      }
    }
  }, [accountData, setAccountData]);

  if (loading) {
    return (
      <div className='app-loader'>
        <Spinner animation='border' />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <HelpCrunchProvider accountData={accountData}>
        <Fragment>
          <ScrollToTop />
          <AppRoutes loading={loading} isLoggedIn={isLoggedIn} />
        </Fragment>
      </HelpCrunchProvider>
    </BrowserRouter>
  );
};

export default UserLocalStorageHook(AccountHook(App));
