import { lazy } from 'react';
import RenderRoutes from 'components/renderRoutes';

export const INDEX_ROUTE = '/';
export const LOGIN_ROUTE = '/login/';
export const SIGNUP_ROUTE = '/signup/';
export const FORGOT_PASSWORD_ROUTE = `${LOGIN_ROUTE}forgot/`;
export const SIGNUP_VERIFY_PHONE_ROUTE = `${SIGNUP_ROUTE}verify-phone/`;

export const ACCOUNT_UPDATE_PASSWORD_ROUTE = '/account/update-password';

export const BARBER_PROFILE_ROUTE = '/:username/';
export const BARBER_PROFILE_REVIEW_ROUTE = `${BARBER_PROFILE_ROUTE}:k?/`;
export const BARBER_BOOKING_SLOT_ROUTE = `${BARBER_PROFILE_ROUTE}time/`;
export const BARBER_REFERRER_ROUTE = `${BARBER_PROFILE_ROUTE}refer-friend/`;
export const BARBER_BOOKING_PROMOTION_ROUTE = `${BARBER_PROFILE_ROUTE}promotions/`;
export const BARBER_BOOKING_CALLOUT_ADDRESS_ROUTE = `${BARBER_PROFILE_ROUTE}callout/`;
export const BARBER_BOOKING_CONFIRMATION_ROUTE = `${BARBER_PROFILE_ROUTE}confirmation/`;
export const BARBER_BOOKING_SUCCESS_ROUTE = `${BARBER_PROFILE_ROUTE}:bookingId/success/`;

export const PROMOTION_ROUTE = '/promotions/';
export const MY_BOOKINGS_ROUTE = '/my-bookings/';
export const PAYMENT_METHODS_ROUTE = '/payment-methods/';

export const IndexComponent = lazy(() => import('pages'));

export const LoginComponent = lazy(() => import('pages/login'));
export const SignupComponent = lazy(() => import('pages/signup'));
export const SignupVerifyPhoneComponent = lazy(() => import('pages/signup/verifyPhone'));
export const ForgotPasswordComponent = lazy(() => import('pages/login/forgotPassword'));

export const AccountUpdatePasswordComponent = lazy(() => import('pages/account/updatePassword'));

export const getProfileRoute = (username) => {
  return BARBER_PROFILE_ROUTE.replace(':username', username);
};

export const getBarberBookingSlotRoute = (username) => {
  return BARBER_BOOKING_SLOT_ROUTE.replace(':username', username);
};

export const getBarberBookingCalloutRoute = (username) => {
  return BARBER_BOOKING_CALLOUT_ADDRESS_ROUTE.replace(':username', username);
};

export const getBarberBookingConfirmationRoute = (username) => {
  return BARBER_BOOKING_CONFIRMATION_ROUTE.replace(':username', username);
};

export const getBarberBookingPromotionRoute = (username) => {
  return BARBER_BOOKING_PROMOTION_ROUTE.replace(':username', username);
};

export const getBarberBookingSuccessRoute = (username, bookingId) => {
  const route = BARBER_BOOKING_SUCCESS_ROUTE.replace(':username', username);
  return route.replace(':bookingId', bookingId);
};

const BarberProfileComponent = lazy(() => import('pages/barber/profile'));
const BookingSuccessComponent = lazy(() => import('pages/booking/success'));
const BookingCallOutComponent = lazy(() => import('pages/booking/callout'));
const BookingSlotComponent = lazy(() => import('pages/booking/timeSelection'));
const BookingConfirmationComponent = lazy(() => import('pages/booking/confirmation'));

const ReferFriendComponent = lazy(() => import('components/refer'));
const MyBookingsComponent = lazy(() => import('pages/customer/myBookings'));
const PaymentMethodComponent = lazy(() => import('pages/customer/paymentMethods'));

export const COMMON_ROUTES = [
  {
    exact: true,
    name: 'login',
    path: LOGIN_ROUTE,
    component: LoginComponent,
  },
  {
    exact: true,
    name: 'booking-slot',
    path: BARBER_BOOKING_SLOT_ROUTE,
    component: BookingSlotComponent,
  },
  {
    exact: true,
    name: 'barber-profile',
    path: BARBER_PROFILE_ROUTE,
    component: BarberProfileComponent,
  },
  {
    exact: true,
    name: 'barber-profile-review',
    path: BARBER_PROFILE_REVIEW_ROUTE,
    component: BarberProfileComponent,
  },
];

export const NON_LOGGED_IN_ROUTES = [
  {
    path: '/',
    name: 'index',
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: 'index',
        path: INDEX_ROUTE,
        component: IndexComponent,
      },
      {
        exact: true,
        name: 'signup',
        path: SIGNUP_ROUTE,
        component: SignupComponent,
      },
      {
        exact: true,
        name: 'forgot-password',
        path: FORGOT_PASSWORD_ROUTE,
        component: ForgotPasswordComponent,
      },
      {
        exact: true,
        name: 'singup-verify-phone',
        path: SIGNUP_VERIFY_PHONE_ROUTE,
        component: SignupVerifyPhoneComponent,
      },
      ...COMMON_ROUTES,
    ],
  },
];

export const LOGGED_IN_ROUTES = [
  {
    path: '/',
    name: 'routes',
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: 'index',
        path: INDEX_ROUTE,
        component: IndexComponent,
      },
      {
        exact: true,
        name: 'singup-verify-phone',
        path: SIGNUP_VERIFY_PHONE_ROUTE,
        component: SignupVerifyPhoneComponent,
      },
      {
        exact: true,
        name: 'account-update-password',
        path: ACCOUNT_UPDATE_PASSWORD_ROUTE,
        component: AccountUpdatePasswordComponent,
      },
      {
        exact: true,
        name: 'booking-callout',
        path: BARBER_BOOKING_CALLOUT_ADDRESS_ROUTE,
        component: BookingCallOutComponent,
      },
      {
        exact: true,
        name: 'booking-confirmation',
        path: BARBER_BOOKING_CONFIRMATION_ROUTE,
        component: BookingConfirmationComponent,
      },
      {
        exact: true,
        name: 'booking-success',
        path: BARBER_BOOKING_SUCCESS_ROUTE,
        component: BookingSuccessComponent,
      },
      {
        exact: true,
        name: 'refer-friend',
        path: BARBER_REFERRER_ROUTE,
        component: ReferFriendComponent,
      },
      {
        exact: true,
        name: 'my-bookings',
        path: MY_BOOKINGS_ROUTE,
        component: MyBookingsComponent,
      },
      {
        exact: true,
        name: 'payment-methods',
        path: PAYMENT_METHODS_ROUTE,
        component: PaymentMethodComponent,
      },
      ...COMMON_ROUTES,
    ],
  },
];
