import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
};

const axiosInstance = axios.create({
  headers,
  // timeout: 4000,
  baseURL: `${process.env.REACT_APP_API_HOST}/api/`,
});

export default axiosInstance;
