import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { setMetaDescription } from "utils";

const ScrollToTop = ({ history }) => {
  function setMeta() {
    document.title = `Barbr - Empowering barbers to thrive`;
    setMetaDescription(
      `Barbr is a platform that helps barbers save time, earn more and unlock their true potential.`
    );
  }
  useEffect(() => {
    const unlisten = history.listen(() => {
      setMeta();
      document.querySelector("body").scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default withRouter(ScrollToTop);
