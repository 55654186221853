import React, { useReducer, useContext, createContext } from 'react';

const CountriesContext = createContext();

export const CountriesProvider = ({ reducer, initialState, children }) => {
  const contextValue = useReducer(reducer, initialState);
  return <CountriesContext.Provider value={contextValue}>{children}</CountriesContext.Provider>;
};

export const CountriesConsumer = () => {
  const contextValue = useContext(CountriesContext);
  return contextValue;
};
