export const initialState = {};

const SET_BARBER_PROFILES_DATA = 'SET_BARBER_PROFILES_DATA';

export const setBarberProfiles = (data) => {
  return {
    payload: data,
    type: SET_BARBER_PROFILES_DATA,
  };
};

export const barberProfilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BARBER_PROFILES_DATA:
      return {
        profiles: action.payload,
      };
    default:
      return { profiles: state };
  }
};

export default barberProfilesReducer;
