import React, { useEffect, useMemo } from 'react';
import { Switch, useHistory, useLocation } from 'react-router';

import * as routes from 'routes';
import RouteWithSubRoutes from 'components/routeWithSubRoutes';
import { useHelpCrunch } from 'context/helpCrunchContext';

const AppRoutes = ({ loading, isLoggedIn }) => {
  const { isHelpCrunchReady } = useHelpCrunch();
  const history = useHistory();
  const location = useLocation();

  const appRoutes = useMemo(() => {
    if (loading) {
      return [];
    }
    if (isLoggedIn) {
      return routes.LOGGED_IN_ROUTES;
    }
    return routes.NON_LOGGED_IN_ROUTES;
  }, [loading, isLoggedIn]);

  useEffect(() => {
    if (history?.location?.pathname === '/') {
      if (isLoggedIn) {
        history.replace(routes.MY_BOOKINGS_ROUTE);
      } else {
        history.replace(routes.LOGIN_ROUTE);
      }
    }
    if (isHelpCrunchReady) {
      window?.HelpCrunch('closeChat');
      window?.HelpCrunch('hideChatWidget');
      setTimeout(() => {
        window?.HelpCrunch('hideChatWidget');
      }, 100);
    }
  }, [isLoggedIn, location, history, isHelpCrunchReady]);

  return (
    <Switch>
      {appRoutes.map((route, i) => {
        return <RouteWithSubRoutes key={i} {...route} />;
      })}
    </Switch>
  );
};

export default AppRoutes;
