import React, { useEffect } from 'react';

const LazyLoadSentry = ({ children }) => {
  useEffect(() => {
    const loadSentry = async () => {
      const Sentry = await import('@sentry/react');
      const { BrowserTracing } = await import('@sentry/tracing');

      Sentry.init({
        environment: process.env.REACT_APP_ENVIRONMENT,
        dsn: 'https://c75f060ca6734529a3311107caeb624f@o500776.ingest.sentry.io/6236967',
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    };

    if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
      setTimeout(loadSentry, 3000);
    }
  }, []);

  return <>{children}</>;
};

export default LazyLoadSentry;
