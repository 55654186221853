export const initialState = {
  signUpBool: false,
  rvParam: null,
  username: null,
};

const SET_REVIEW_SIGN_UP_BOOL = 'SET_REVIEW_SIGN_UP_BOOL';

export const setReviewSignUpBool = (data) => {
  return {
    payload: data,
    type: SET_REVIEW_SIGN_UP_BOOL,
  };
};

export const reviewSignUpBoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REVIEW_SIGN_UP_BOOL:
      return {
        signUpReview: action.payload,
      };
    default:
      return { signUpReview: state };
  }
};

export default reviewSignUpBoolReducer;
